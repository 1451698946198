<template>
  <div style="margin-top: 80px;">
    <el-row>
      <el-col :span="24">
        <div class="container">
          <el-container class="pc-container">
            <el-header height="200px">
              <div class="pc-banner">
                <el-row style="height: 100%;">
                  <el-col :span="4" style="margin: auto 0; display:flex; justify-content: flex-start">
                    <el-image
                      class="pc-profile"
                      :src="profileUri"
                      fit="fit"></el-image>
                  </el-col>
                  <el-col :span="20" style="margin: auto;">
                    <el-row style="text-align: left;display:flex;align-items:flex-end;margin-bottom: 30px">
                      <el-col :span="20"><span class="pc-real-name"><strong>{{ (userInfos.realName ? userInfos.realName : userInfos.mobile) || '-' }}</strong></span><span class="pc-vip-state">{{ vipDatas.vipLevel === '00010002'? 'VIP会员' : '' }}</span></el-col>
                      <el-col :span="4">
                        <el-button class="pc-renewal" @click="handlerRecharge()"><strong>{{(vipDatas.vipLevel == '00010002' ? '续费VIP' : '开通VIP') || '开通VIP'}}</strong></el-button>
                      </el-col>
                    </el-row>
                    <el-row style="text-align: left">
                      <el-col :span="6" v-if="vipDatas.vipLevel == '00010002'">有效期至：<span>{{vipDatas.endTime ? vipDatas.endTime.substring(0, 10) : '-' }}</span></el-col>
                      <el-col :span="6">手机号：<span>{{ userInfos.mobile || '-' }}</span></el-col>
                      <el-col :span="6">邮箱：<span @click="userInfos.enterpriseContactEmail ? '' : handleTo()" :class="userInfos.enterpriseContactEmail ? '' : 'font-hand'">{{ userInfos.enterpriseContactEmail ? userInfos.enterpriseContactEmail : '未绑定' }}</span></el-col>
                      <el-col :span="6">认证企业：<span @click="userInfos.businessInformationStatus === '1' ? '' : handleTo()" :class="userInfos.businessInformationStatus === '1' ? '' : 'font-hand'">{{ userInfos.businessInformationStatus === '1' ? '已认证' : '未认证' }}</span></el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </el-header>
            <el-container>
              <el-aside width="285px">
                <el-menu :default-active="activeMenuIndex"
                         class="el-menu-vertical-demo pc-menu"
                         background-color="#FFFFFF"
                         active-text-color="#5D6FE9"
                         router="true">
                  <el-menu-item index="message-center">
                    <div>消息中心 <span v-if="mesCont > 0 " class="mesClass">{{mesCont}}</span></div>
                  </el-menu-item>
                  <el-menu-item index="attention-company">
                    <div>我的关注 <span v-if="foucsCont > 0 " class="mesClass">{{foucsCont}}</span></div>
                  </el-menu-item>
                  <el-menu-item index="coupon">
                    <div>我的卡券</div>
                  </el-menu-item>
                  <el-menu-item index="data-maintain-person">
                    <div>维护记录</div>
                  </el-menu-item>
                  <el-menu-item index="order">
                    <div>我的订单</div>
                  </el-menu-item>
                  <el-menu-item index="invoice-list">
                    <div>我的发票</div>
                  </el-menu-item>
                  <el-menu-item index="associated-account">
                    <div>关联账户</div>
                  </el-menu-item>
                  <el-menu-item index="account-security">
                    <div>账号安全</div>
                  </el-menu-item>
                </el-menu>
                <sapn></sapn>
              </el-aside>
              <el-main style="min-height: 500px;">
                <router-view></router-view>
              </el-main>
            </el-container>
          </el-container>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFoucsByUserId } from '@/api/user.js'
export default {
  name: 'personal-center',
  data () {
    return {
      profileUri: require('@/assets/images/profile.png'),
      userInfos: this.$store.getters.userInfo ? JSON.parse(this.$store.getters.userInfo) : {},
      vipDatas: JSON.parse(this.$store.getters.vipData),
      activeMenuIndex: this.$route.name
    }
  },
  methods: {
    handlerRecharge () {
      this.$router.push({ name: 'vip-index' })
    },
    handleTo () {
      this.$router.push({ name: 'account-security' })
    },
    loadData (currentPage) {
      const params = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        pageNum: 1,
        pageSize: 1000
      }
      getFoucsByUserId(params).then(result => {
        if (result.code === 0) {
          let num = 0
          const res = result.data.list
          res.forEach(item => {
            if (item.unreadMessagesStatus === 1) {
              num += 1
            }
          })
          this.$store.dispatch('setFoucsCont', num)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
  mounted () {
    this.loadData()
    console.log(this.vipData)
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'mesCont',
      'foucsCont'
    ]),
    vipLevelChange: function () {
      const vm = this
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData)
      } else {
        return null
      }
    }
  },
  watch: {
    vipLevelChange: function (newValue, oldValue) {
      this.vipDatas = this.$store.getters.vipData ? JSON.parse(this.$store.getters.vipData) : {}
    },
    userInfo: function (newValue, oldValue) {
      this.userInfos = this.$store.getters.userInfo ? JSON.parse(this.$store.getters.userInfo) : {}
    },
    '$route' (to, from) {
      // console.log('to: ' + to + ' | from: ' + from)
      // console.log(to)
      // console.log(from)
      // console.log(this.$store.state.curPage)
      // this.activeMenuIndex = this.$store.state.curPage
      if (this.$route.name === 'invoice') {
        this.activeMenuIndex = 'invoice-list'
      } else {
        this.activeMenuIndex = this.$route.name
      }
    }
  }
}
</script>

<style scoped>
/deep/ .el-header {
  padding: 0;
}

.pc-banner {
  height: 200px;
  width: 1200px;
  background-image: linear-gradient(to bottom, #4775E4, #7E65F5);
  margin: auto;
  color: #fff;
}

.pc-profile {
  width: 110px;
  height: 110px;
  border: 2px solid #FFFFFF;
  border-radius: 55px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)*/
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /*margin-top: 45px;*/
  margin-left: 60px;
}

.pc-real-name {
  font-size: 24px;
}

.pc-vip-state {
  color: #F8C58A;
  margin-left: 20px;
}

.pc-renewal {
  border: none;
  font-size: 14px;
  color: #409EFF;
  background-image: linear-gradient(to bottom right, #F4E9DA, #F8C58A);
}

.pc-menu div {
  display: block;
  width: 100%;
  text-align: left;
}

/deep/ .el-main {
  padding-right: 0;
}

/deep/ .el-aside {
  margin-top: 20px;
}
<<<<<<< .mine
/deep/ .el-menu-item.is-active {
  background-color: #E8EBFC !important;
}
/deep/ .el-menu-item.is-active:hover {
  background-color: #E8EBFC !important;
}
.el-menu-item {
  border-bottom: 1px solid #EBEBEB;
}
/deep/ .el-menu-item:hover {
  color: #5D6FE9;
  background-color: rgba(0,0,0,0) !important;
}
 /deep/ .el-menu-item.is-active {
  background-color: #E8EBFC !important;
}
/deep/ .el-menu-item.is-active {
  background-color: #E8EBFC;
}
.font-hand {
  cursor: pointer;
}
.mesClass {
  font-size: 12px;
  width: 20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  height: 20px;
  background-color: red;
  color: #fff;
  border-radius: 20px;
}
</style>
